function initializeFormulaExamples() {
    const el = document.querySelector('#formula-example');

    if ( !el ) {
        return;
    }

    el.addEventListener('click', function () {
        const xhr = new XMLHttpRequest();

        const url = '/admin/settings/formula-example';

        xhr.onreadystatechange = function () {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const data = JSON.parse(xhr.responseText);

                document.querySelector('#enkelePlooiFormulaExample').innerHTML = data.enkelePlooiFormula;
                document.querySelector('#dubbelePlooiFormulaExample').innerHTML = data.dubbelePlooiFormula;
                document.querySelector('#enkeleRetourplooiFormulaExample').innerHTML = data.enkeleRetourplooiFormula;
                document.querySelector('#dubbeleRetourplooiFormulaExample').innerHTML = data.dubbeleRetourplooiFormula;
                document.querySelector('#wavePlooiFormulaExample').innerHTML = data.wavePlooiFormula;
                document.querySelector('#ingeslagenRingenFormulaExample').innerHTML = data.ingeslagenRingenFormula;
                document.querySelector('#vouwGordijnFormulaExample').innerHTML = data.vouwGordijnFormula;
            }
        }
        xhr.open("POST", url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({
            '_token': document.querySelector('meta[name=csrf-token]').getAttribute('content'),
            enkelePlooiFormula: document.querySelector('input[name=enkelePlooiFormula]').value,
            dubbelePlooiFormula: document.querySelector('input[name=dubbelePlooiFormula]').value,
            enkeleRetourplooiFormula: document.querySelector('input[name=enkeleRetourplooiFormula]').value,
            dubbeleRetourplooiFormula: document.querySelector('input[name=dubbeleRetourplooiFormula]').value,
            wavePlooiFormula: document.querySelector('input[name=wavePlooiFormula]').value,
            ingeslagenRingenFormula: document.querySelector('input[name=ingeslagenRingenFormula]').value,
            vouwGordijnFormula: document.querySelector('input[name=vouwGordijnFormula]').value,
        }));
    });
}

initializeFormulaExamples();
