import QrScanner from 'qr-scanner';
import QrScannerWorkerPath from '!!file-loader!../../../node_modules/qr-scanner/qr-scanner-worker.min.js';
QrScanner.WORKER_PATH = QrScannerWorkerPath;

let qrScanner = null;

if ( document.getElementById('webcam-scanner') ) {

    const scannerNode = document.getElementById('webcam-scanner');

    if ( !QrScanner.hasCamera() ) {
        alert('No camera detected! Please make sure there is one connected and refresh the page.');

    } else {
        qrScanner = new QrScanner(scannerNode, handleQRCodeScan);
        qrScanner.start();

        scannerNode.parentNode.insertBefore(qrScanner.$canvas, scannerNode.nextSibling);
    }
}

function handleQRCodeScan(scanned) {
    if ( qrScanner === null ) {
        return;
    }

    qrScanner.stop();

    let qr_code = window.parseScan(scanned);

    if ( qr_code !== false ) {
        window.location.href = '/scanner/scan/' + qr_code;
    } else {
        alert(scanned + ' is not a correct url. QR-code not found!');
        qrScanner.start();
    }
}

window.parseScan = function (urlString) {
    let url;
    try {
        url = new URL(urlString);
    } catch (error) {
        if ( error instanceof TypeError ) {
            return false;
        }
        throw error;
    }

    const qr_code = url.searchParams.get('qr-code');

    if (qr_code === null) {
        return false;
    }

    return qr_code;
}
