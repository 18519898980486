function initializePricesButton() {
    const pricesButton = document.querySelector('#calculate-prices');

    if ( pricesButton === null ) {
        return;
    }

    pricesButton.addEventListener('click', function() {
        const price = document.querySelector('input[name=advised_price]').value;

        const xhr = new XMLHttpRequest();

        const yourUrl = '/admin/curtain/calculate-prices'
        xhr.onreadystatechange = function() {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const data = JSON.parse(xhr.responseText);

                document.querySelector('input[name=single_fold_price]').value = data.enkele_plooi_price;
                document.querySelector('input[name=double_fold_price]').value = data.dubbele_plooi_price;
                document.querySelector('input[name=single_alternating_fold_price]').value = data.enkele_retourplooi_price;
                document.querySelector('input[name=double_alternating_fold_price]').value = data.dubbele_retourplooi_price;
                document.querySelector('input[name=waving_fold_price]').value = data.wave_plooi_price;
                document.querySelector('input[name=ringed_fold_price]').value = data.ingeslagen_ringen_price;
            }
        }
        xhr.open("POST", yourUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({
            '_token': document.querySelector('meta[name=csrf-token]').getAttribute('content'),
            price: price
        }));
    })
}

initializePricesButton();
