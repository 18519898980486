const massCheckboxSelects = document.querySelectorAll('.mass-checkbox-select');

for (const massCheckboxSelect of massCheckboxSelects) {
    massCheckboxSelect.addEventListener('change', (event) => {
        let target = event.target.getAttribute('data-target');
        const checkboxes = document.querySelectorAll("input[type=checkbox][data-"+target+"=\""+event.target.value+"\"]");
        for (const checkbox of checkboxes){
            checkbox.checked = true;
        }
    })
}
