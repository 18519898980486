function initializeScannerInput() {
    const element = document.querySelector('[data-component=scanner-input]');

    if ( !element ) {
        return;
    }

    let buffer = '';

    document.addEventListener('keypress', function (event) {
        const key = event.key;

        if ( key === 'Enter' ) {
            submitScanner(buffer);
            buffer = '';
        } else {
            buffer += key;
        }
    });
}

function submitScanner(scanned) {
    let qr_code = window.parseScan(scanned);

    if ( qr_code !== false ) {
        window.location.href = '/scanner/scan/' + qr_code;
    }
}

initializeScannerInput();
